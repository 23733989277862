/*eslint-disable */
// import GPathCareertypeModel from '../../Model/GPathCareertype'
import request from '../../Utils/curl'

let GPathCareertypes = {
  /**
   * gpath_career_typeList
   */
  async gpath_career_typeList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_career_type_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_career_typeList() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_typeView
   */
  async gpath_career_typeView (context, gpathCareerTypeId) {
    try {      
      let post_data = new FormData();
      post_data.append('gpct_id', gpathCareerTypeId);
      return await request.curl(context, "gpath_career_type_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_career_typeView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_career_typeAdd
   */
  async gpath_career_typeAdd (context, gpath_career_typeObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_typeObj) {
        post_data.append(key, gpath_career_typeObj[key]);
      }

      return await request.curl(context, "gpath_career_type_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_typeAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_typeEdit
   */
  async gpath_career_typeEdit (context, gpath_career_typeObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_typeObj) {
        post_data.append(key, gpath_career_typeObj[key]);
      }

      return await request.curl(context, "gpath_career_type_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_typeEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_typeDelete
   */
  async gpath_career_typeDelete (context, gpathCareerTypeId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gpct_id', gpathCareerTypeId);

      return await request.curl(context, "gpath_career_type_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_career_typeDelete() and Exception:',err.message)
    }
  }
}

export {
  GPathCareertypes
}
